import {
	RouteConfig
} from 'react-router-config';
import Layout from '@views/Layout/Layout';
import Detail from '@views/Detail/Detail';
import RepositoryList from '@views/RepositoryList/RepositoryList';
import MatchmakerDetail from '@views/MatchmakerDetail/MatchmakerDetail';
import Questionnaire from '@src/views/Questionnaire/Questionnaire';
import WriteInformation from '@views/WriteInformation/index';
import MP_Questionnaire from '@src/views/MP_Contribution/Questionnaire/Questionnaire';
import MP_WriteInformation from '@views/MP_Contribution/WriteInformation/index';
import Com_Questionnaire from '@src/views/Community/Questionnaire/Questionnaire';
import Com_WriteInformation from '@views/Community/WriteInformation/index';
import Nine_Questionnaire from '@src/views/Nine/Questionnaire/Questionnaire';
import Nine_WriteInformation from '@views/Nine/WriteInformation/index';
import coreInfo from '@views/CoreInfo/coreInfo';
import Member from '@views/Member/Member';
import MemberNew from '@views/Member/MemberNew';
import Recharge from '@views/Recharge/Recharge';
import UserAgreement from '@views/UserAgreement/UserAgreement';
import Mini_Questionaire from '@mini/DataFill/Questionnaire/Questionnaire';
import Mini_Writeinformation from '@mini/DataFill/WriteInformation/index';
import Mini_Core from '@mini/CoreInfo/coreInfo';
import Activity from '@views/Activity/Activity';
import CustomShare from '@src/views/Custom/share';
import CustomPrice from '@src/views/Custom/price';
import UserBind from '@src/views/ForOperate/UserBind/Detail';
import FollowUp from '@src/views/ForOperate/FollowUp';
import Coupon from '@src/views/Coupon/Coupon';
import PrivacyAgreement from '@views/PrivacyAgreement/PrivacyAgreement';
import ThirdPartyList from '@views/ThirdPartyList/ThirdPartyList';

const forOperate = [ {
		path: '/user/bind/:id?',
		component: UserBind
	},
	{
		path: '/follow-up/:id',
		component: FollowUp
	}
];
export default [ {
	path: '/',
	routes: [ {
			path: '/:name?',
			exact: true,
			component: Layout
		},
		{
			path: '/detail/:id/:name/:third?',
			component: Detail
		},
		{
			path: '/matchmaker-detail/:id',
			component: MatchmakerDetail
		},
		{
			path: '/repository-list',
			component: RepositoryList
		},
		{
			path: '/questionnaire/:type/:id',
			component: Questionnaire
		},
		{
			path: '/write-information/:id/:step',
			component: WriteInformation
		},
		{
			path: '/mp/questionnaire/:type/:id',
			component: MP_Questionnaire
		},
		{
			path: '/mp/write-information/:id/:step',
			component: MP_WriteInformation
		},
		{
			path: '/community/question/:type/:id',
			component: Com_Questionnaire
		},
		{
			path: '/community/write/:id/:step',
			component: Com_WriteInformation
		},
		{
			path: '/core/write/:id',
			component: coreInfo
		},
		{
			path: '/nine/question/:id',
			component: Nine_Questionnaire
		},
		{
			path: '/nine/write/:id/:step',
			component: Nine_WriteInformation
		},
		{
			path: '/user/member',
			component: Member
		},
		{
			path: '/mini/member',
			component: MemberNew
		},
		{
			path: '/user/recharge',
			component: Recharge
		},
		{
			path: '/user/agree',
			component: UserAgreement
		},
		{
			path: '/user/privacy',
			component: PrivacyAgreement
		},
		{
			path: '/mini/question/:type/:code?',
			component: Mini_Questionaire
		},
		{
			path: '/mini/data',
			component: Mini_Writeinformation
		},
		{
			path: '/mini/core/:code',
			component: Mini_Core
		},
		{
			path: '/user/activity',
			component: Activity
		},
		{
			path: '/custom/share/:id?',
			component: CustomShare
		},
		{
			path: '/custom/price',
			component: CustomPrice
		},
		{
			path: '/user/third-party-list',
			component: ThirdPartyList
		},
		{
			path: '/mp/coupon',
			component: Coupon
		},
		...forOperate
	]
} ] as RouteConfig[];
