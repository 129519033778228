import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import './ThirdPartyList.scss';
import list from './storeList';

interface State {
	key: any;
  storeList: any;
}
interface mineProps {}
interface DispatchProps {}
type Props = DispatchProps & RouteComponentProps & mineProps;

class ThirdPartyList extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			key: '',
			storeList: []
		};
	}

	componentDidMount() {
		this.updateDocumentTitle();
		this.setState({
			key: Date.now(),
			storeList: list.split('、\n').map((item) => ({
				kind: '用户账户信息（含登录用的手机号码、性别、年龄、身高、学历、收入、职业、现居地、姓名、微信号等）、用户理想对象信息',
				objective: '解决用户婚恋需求，向用户提供线下个性化婚恋服务和营销',
				scene: '用户使用一起脱单小程序推荐功能时',
				mode: '后台提供',
				type: '联合婚恋邀约中心',
				name: item.trim()
			}))
		});
	}

	componentDidUpdate() {
		this.updateDocumentTitle();
	}

	updateDocumentTitle() {
		document.title = '第三方数据合作清单';
	}

	render() {
		const { storeList } = this.state;
		return (
			<div className="ThirdPartyList">
				<table className="third-table">
					<tbody>
						<tr>
							<td align="left" className="tableHeader type-style" colSpan={7}>
								第三方SDK合作说明
							</td>
						</tr>
						<tr>
							<td align="center" className="tableHeader type-style">SDK名称</td>
							<td align="center" className="tableHeader type-style">公司名称</td>
							<td align="center" className="tableHeader type-style">
								收集的个人信息种类
							</td>
							<td align="center" className="tableHeader type-style">使用目的</td>
							<td align="center" className="tableHeader type-style">使用场景</td>
							<td align="center" className="tableHeader type-style">数据传输方式</td>
							<td align="center" className="tableHeader type-style">隐私政策/官网</td>
						</tr>
						<tr>
							<td className="tableCell">腾讯云E证通 SDK</td>
							<td className="tableCell">腾讯云计算（北京）有限责任公司</td>
							<td className="tableCell">
								身份证信息（姓名、身份证号码）、人脸视频和照片
							</td>
							<td className="tableCell">提供安全身份验证服务</td>
							<td className="tableCell">进行安全身份验证</td>
							<td className="tableCell">SDK本机采集、接口传输</td>
							<td className="tableCell">
								<a href="https://cloud.tencent.com/document/product/1007/93208">
									https://cloud.tencent.com/document/product/1007/93208
								</a>
							</td>
						</tr>
						<tr>
							<td className="tableCell">阿里云短信认证SDK</td>
							<td className="tableCell">阿里巴巴云计算（北京）有限公司</td>
							<td className="tableCell">手机号</td>
							<td className="tableCell">短信认证</td>
							<td className="tableCell">手机号验证码</td>
							<td className="tableCell">SDK本机采集、接口传输</td>
							<td className="tableCell">
								<a
									href="https://terms.alicdn.com/legal-agreement/terms/privacy_policy_full/20230922101800634/20230922101800634.html"
								>
									https://terms.alicdn.com/legal-agreement/terms/privacy_policy_full/20230922101800634/20230922101800634.html
								</a>
							</td>
						</tr>
						<tr>
							<td align="left" className="tableHeader type-style" colSpan={7}>
								授权方共享说明
							</td>
						</tr>

						<tr>
							<td align="center" className="tableHeader type-style">第三方类型</td>
							<td align="center" className="tableHeader">第三方名称</td>
							<td align="center" className="tableHeader">共享的个人信息种类</td>
							<td align="center" className="tableHeader">共享目的</td>
							<td align="center" className="tableHeader">共享场景</td>
							<td align="center" className="tableHeader mode-style">共享方式</td>
							<td align="center" className="tableHeader type-style">
								第三方个人信息处理规则
							</td>
						</tr>
						{
							storeList.map((item) => (
								<tr key={item.name}>
									<td className="tableCell">{ item.type }</td>
									<td className="tableCell">{ item.name }</td>
									<td className="tableCell">{ item.kind }</td>
									<td className="tableCell">{ item.objective }</td>
									<td className="tableCell">{ item.scene }</td>
									<td className="tableCell">{ item.mode }</td>
									<td className="tableCell">/</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		);
	}
}

export default withRouter(ThirdPartyList);
