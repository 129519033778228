export default `极速热恋(广州)婚姻介绍有限公司（极速热恋）、
北京京恋信息科技有限公司（京恋）、
北京京城热恋科技有限公司（京城热恋）、
深圳市爱来无忧婚姻介绍有限公司（爱来无忧）、
深圳市爱来空间技术咨询有限公司（爱来空间）、
上海禧糖信息技术有限公司（禧糖.乐恋）、
福州鼓楼区转角爱信息科技有限公司（转角爱）、
武汉福缘婚姻服务有限公司（遇见福缘）、
武汉七小鹿婚恋服务有限公司（七小鹿）、
西安倾城热恋信息科技有限公司（倾城热恋）、
厦门鹭岛有约信息科技有限公司（鹭岛有约）、
合肥悠芮卡婚姻服务有限公司（有幸遇见你）、
北京京恋信息科技有限公司大连分公司（京恋）、
杭州与你相遇文化创意有限公司（与你相遇）、
杭州杜拉拉信息科技有限公司（MarryU Club）、
合肥甜派婚姻服务有限公司（甜派）、
深圳市思定终身信息咨询有限公司（私定终身）、
广州市优爱空间信息技术有限公司（优爱空间）、
福州市爱语千寻婚姻服务有限公司（欢喜）、
广州爱渡情感信息咨询有限公司（爱渡）、
上海甄馨咚信息科技有限公司（恋爱club）、
苏州启缘情感信息技术有限公司（启缘）、
湖南联姻荟婚恋服务有限责任公司（联姻荟）、
广州恋爱树婚姻服务有限公司（爱盟恋爱树）、
上海甄馨咚信息科技有限公司（恋爱club）、
上海只有你信息咨询服务有限公司（Only U）、
珠海爱之恋信息技术有限公司（爱之恋）、
湖北可心文化传播有限公司（湖北可心文化传播有限公司）、
长沙一起脱单婚介服务有限公司（一起脱单）、
东莞简爱婚姻介绍服务有限公司（莞城遇见爱）、
长沙一起脱单文化科技有限公司（长沙一起脱单文化科技有限公司）、
珍恋（中山）信息技术有限公司（珍恋）、
深圳我主良缘牵爱技术有限公司（我主良缘）、
惠州市喜遇婚姻服务有限公司（你的先生）、
纯恋（广州）互联网科技有限公司肇庆分公司（纯恋）、
佛山市缘小牵文化传播有限公司（椿橙结）、
深圳市优爱信息技术有限公司（优爱空间）、
北京爱渡时代信息技术有限公司（优爱）、
广州值得爱信息技术有限公司（优爱空间）、
深圳市遇见良缘情感咨询有限公司（爱渡）、
深圳半熟恋人情感咨询有限公司（爱渡）、
东莞市爱马信息科技有限公司（爱马）、
缘木佳人文化(东莞)有限公司（缘木佳人）、
南京今生唯伊婚恋服务有限公司（今生唯伊）、
上海爱渡优恋信息科技有限公司（爱渡）、
长沙盈呗广告传媒有限公司（大先生婚恋）、
长沙春和日丽信息咨询有限公司（月老牵线）、
上海趣纸条婚姻介绍服务有限公司（趣纸条）、
上海好姻缘文化科技有限公司（好姻缘）、
荟禧（厦门）信息技术有限公司（荟禧）、
长沙大先生婚恋服务有限公司（大先生）、
武汉市甜蜜科技有限责任公司（甜蜜缘）、
武汉市倾城时光婚姻服务有限公司（倾城时光）、
恋爱无限信息科技(无锡)有限公司（缘来）、
江门市往后余生婚姻服务有限公司（往后余生）、
南京遇见心动文化科技有限公司（遇见心动）、
吉安红本本信息技术有限公司（世纪佳缘）、
广州恋爱吧婚姻介绍有限公司（完美遇见）、
超级红娘(深圳)信息科技有限公司（超级红娘）、
西安二十六度空间信息技术有限公司（26°C）、
北京余生有约科技有限公司（余生有约）、
上海授人以渔信息科技有限公司（ 爱特TA@心动的TA）、
上海芊芊爱婚姻介绍服务有限公司（芊芊爱）、
杭州初尔科技有限公司（初尔脱单空间）、
苏州恋爱树婚姻服务有限公司（恋爱树）、
杭州佳偶天成信息技术有限公司（佳偶天成）、
绍兴盟约婚姻咨询服务有限公司（百合网）、
中山市唯爱婚姻服务有限公司（唯爱婚恋）、
上海爱渡优恋信息科技有限公司（I DO）、
江西爱沐婚恋服务有限公司（爱沐）、
南昌蜜恋婚姻服务有限公司（蜜恋·金婚）、
深圳市心动连线情感咨询有限公司（爱渡）、
武汉花好又月圆科技有限公司（武汉花好月圆）、
佛山市随爱信息技术有限公司（喜又）、
福州市欢喜婚姻服务有限公司（欢喜俱乐部）、
厦门至爱恋人信息科技有限公司（至爱恋人）、
厦门爱渡信息咨询有限公司（爱渡）、
郑州二十六空间信息技术有限公司（26°C space）、
南京佳期如梦婚介服务有限公司（MarryU）、
宁波优爱红娘婚姻介绍服务有限公司（优爱红娘）、
上海缘起佳恋婚姻介绍服务有限公司（缘起佳恋）、
宁波伴糖文化传媒有限公司（甬诚红娘）、
成都婚查查科技有限公司（婚查查）、
重庆婚查查科技有限公司（婚查查）、
无锡优恋婚介咨询有限公司（优恋）、
北京京恋信息科技有限公司北京市朝阳分公司（京恋）、
北京京恋信息科技有限公司大连分公司（京恋）、
杭州相遇婚恋网络科技有限责任（脱单中心）、
南京全城热恋婚姻服务有限公司（全城热恋）、
南京恋爱树婚姻服务有限公司（恋爱树）、
西安爱渡情感信息技术有限公司（爱渡）、
苏州优恋信息技术有限公司（优恋）、
佛山遇见爱婚姻服务有限公司（佛山遇见爱）、
武汉启缘网婚姻介绍服务有限公司（恋爱中心）、
徐州全城热恋婚姻服务有限公司（全城热恋）、
厦门至爱恋人信息科技有限公司（至爱恋人）、
漳州倾心婚姻服务有限公司（漳州倾心）、
南京爱渡信息技术有限公司（爱渡）、
南宁爱唯情感婚姻服务有限公司（爱唯）、
上海唯尔一笙婚姻介绍服务有限公司（唯尔一笙）、
佛山市铱爱信息技术有限公司（铱爱情缘）、
上海薇宜婚姻介绍服务有限公司（薇宜）、
青岛佳偶意诚婚恋服务有限公司（佳偶意诚）、
深圳市爱盟信息技术有限公司（深圳市爱盟信息技术有限公司）、
厦门妙合高端婚恋服务有限公司（妙合）、
无锡佳偶良缘信息技术有限公司（佳偶良缘）、
长沙市屿你同行文化传播有限公司（屿你同行）、
合肥市挚爱信息技术有限公司（挚爱）、
苏州世纪优恋婚姻服务有限公司（世纪优恋）、
广州金鹊粤信息技术有限公司（浪漫有你）、
成都市爱乐空间奥克科技有限公司（爱乐空间）、
上海菁英之约科技服务有限公司（菁英之恋）、
杭州我们结婚吧信息服务有限公司（我们结婚吧）、
北京良辰文化创意有限公司（良辰）、
宁波牵对信息技术有限公司（媒小仙）、
深圳铂爱婚姻情感咨询服务有限公司公司（铂爱＆fy）、
北京月娘信息科技有限公司（月娘婚恋）、
宁波甬城热恋婚姻咨询服务有限公司（天赐良缘）、
合肥悠芮卡婚姻服务有限公司（有幸）、
上海妙蛙有爱文化传播有限公司（爱情故事）、
东莞市你和他信息技术有限公司（你&Ta）、
惠州市喜遇婚姻服务有点公司（Mr'R CLUB 你的先生）、
perfect matcing company limited（perfect 完美遇见）、
武汉臻爱良缘婚恋服务有限公司（遇见·囍）、
常州渊之圆婚恋服务有限公司（百合园）、
宁波遇爱信息技术有限责任公司（禧上喜）、
广州锦秀信息服务有限公司（锦秀文化）、
成都市锦城热恋信息技术有限公司（锦城热恋）、
昆明顺禧信息技术有限公司（顺禧）、
陕西世纪良缘科技有限公司（世纪良缘）、
北京缘来有你婚姻服务有限公司（缘聚）`;
